import "./App.css";

import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import React, { Component } from "react";
import Sample from "./Sample.js"
import Home from "./Pages/Home";
import InitiateFurtherDiagnostics from "./Pages/InitiatFurtherDiagnostics/";
import NavigationBar from "./Components/Navigation";
import RunDiagnostics from "./Pages/RunDiagnostics";
import SpeedTestDetails from "./Pages/SpeedTestDetails";
import SpeedTestGuidance from "./Pages/SpeedTestGuidance";
import NotFoundPage from './NotFoundPage.js';


class App extends Component {
  render() {
    return (
        <div className="container">
          < div className="row" ><NavigationBar/></div>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/guidance" component={SpeedTestGuidance} />
              <Route
                path="/details"
                component={SpeedTestDetails}
                componentwillmount
              />
              <Route
                path="/runDiagnostics"
                component={RunDiagnostics}
                componentwillmount
              />
              <Route
                path="/initiateFurtherDiag"
                component={InitiateFurtherDiagnostics}
                componentwillmount
              />
              <Route
                path="/sample"
                component={Sample}
                componentwillmount
              />
              
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Router>
          
        </div>
    );
  }
}

export default App;
