import "react-responsive-carousel/lib/styles/carousel.min.css";

import React, { Component } from "react";
import card3 from "../../Assets/antenna.png"
import Card1 from "../../Assets/card1.png";
import Card11 from "../../Assets/card1.1.png";
import { Carousel } from "react-responsive-carousel";
import ReactDOM from "react-dom";
import card1 from "../../Assets/icons8-internet-64.png";

class DCarousel extends Component {
  render() {
    return (
      <Carousel showThumbs={false} showStatus={false}>
        
          <div
            style={{
              background: "#5717B4",
              padding: 20,
              width: 500,
              height: 487,
              textAlign: "left"
            }}
          >
            <div
              style={{
                fontFamily: "Century Gothic",
                textAlign: "left",
                marginLeft: 13,
                marginTop: 15,
                color: "#fff",
                fontWeight: "bold",
                fontSize: 14
              }}
            >
              <span>Before You Start?</span>

              <br />
              <div
                style={{
                  textAlign: "center",
                  background: "#5717B4",
                  width: 30,
                  height: 30,
                  paddingTop: 5,
                  borderColor: "#FF90FF",
                  borderStyle: "solid",
                  borderWidth: 1.3,
                  fontSize: 15,
                  marginTop: 10
                }}
              >
                <a>1</a>
              </div>

              <img
                style={{ width: 60, height: 60, marginLeft: 140 }}
                src={Card11}
              ></img>
              <img
                style={{ width: 60, height: 60, marginLeft: 30 }}
                src={Card1}
              ></img>

              <br />
              <br />
              <p
                style={{
                  fontWeight: "normal",
                  color: "#FFFFFF",
                  fontStretch: "condensed",
                  fontSize: 13
                }}
              >
                Your broadband speed can be affected by different factors
                <br />
                including; the technology used to deliver it to your
                premises,the
                <br />
                type of connection you used to connect your device e.g. PC,
                <br />
                laptop, smartphone etc. and the type/model of
                <br />
                modem/router/hub.
                <br />
                Sometimes the most basic things can fix your problem. If you are
                experiencing performance issues with your Broadband service it's
                always worth trying the following before launching into a full
                on diagnostic and faulting process:
                <br />
                1. Restart your browser and clear cache.
                <br />
                2. Reboot your modem/router/hub by powering it off, waiting one
                minute, and then powering it up again. Wait for any status
                lights on
                <br /> the router to stabilise before starting the test.
              </p>
            </div>
          </div>
        

        
          <div
            style={{
              background: "#5717B4",
              padding: 20,
              width: 500,
              height: 487,
              textAlign: "left"
            }}
          >
            <div
              style={{
                fontFamily: "Century Gothic",
                textAlign: "left",
                marginLeft: 13,
                marginTop: 15,
                color: "#fff",
                fontWeight: "bold",
                fontSize: 14
              }}
            >
              <span>To get the best from the test please check:</span>
              <br />
              <div
                style={{
                  textAlign: "center",
                  background: "#5717B4",
                  width: 30,
                  height: 30,
                  paddingTop: 5,
                  borderColor: "#FF90FF",
                  borderStyle: "solid",
                  borderWidth: 1.3,
                  fontSize: 15,
                  marginTop: 10
                }}
              >
                <a>2</a>
              </div>

              <img
                style={{ width: 60, height: 60, marginLeft: 190 }}
                src={card1}
              ></img>
              <br />
              <br />
              <p
                style={{
                  fontWeight: "normal",
                  color: "#FFFFFF",
                  fontStretch: "condensed",
                  fontSize: 13
                }}
              >
                1. The device you are running the test from is connected to the
                broadband line to be tested.
                <br />
                2. Your device is directly connected to your modem/router via an
                Ethernet Connection (i.e. not via a wireless device or powerline
                adapter) this will provide a more accurate test. Results can
                vary if you use a Wireless/Wi-Fi connection.
                <br />
                3. Close any programs that may be running on your device,
                including any background programs such as anti-virus software,
                corporate VPNs, peer2peer clients etc. (Please do re-enable any
                anti-virus software after you have finished testing).
                <br />
                4. Ensure no one else is using the Broadband service while you
                are running the test. Other people/devices e.g. TV set-top
                boxes, Internet of Things (IOT) devices can disrupt the test.
              </p>
            </div>
          </div>
        
          <div
            style={{
              background: "#5717B4",
              padding: 20,
              width: 500,
              height: 487,
              textAlign: "left"
            }}
          >
            <div
              style={{
                fontFamily: "Century Gothic",
                textAlign: "left",
                marginLeft: 13,
                marginTop: 15,
                color: "#fff",
                fontWeight: "bold",
                fontSize: 14
              }}
            >
              <span>And finally:</span>
              <br />

              <div
                style={{
                  textAlign: "center",
                  background: "#5717B4",
                  width: 30,
                  height: 30,
                  paddingTop: 5,
                  borderColor: "#FF90FF",
                  borderStyle: "solid",
                  borderWidth: 1.3,
                  fontSize: 15,
                  marginTop: 10
                }}
              >
                <a>3</a>
              </div>

              <img
                style={{ width: 60, height: 60, marginLeft: 190 }}
                src={card3}
              ></img>
              <br />
              <br />

              <p
                style={{
                  fontWeight: "normal",
                  color: "#FFFFFF",
                  fontStretch: "condensed",
                  fontSize: 13
                }}
              >
                1. It can take up to 10 days from set-up of a new service, or
                following <br />a ccSNR Reset for your broadband line to train
                up to its full speed. Do bear in mind that any speed test during
                this period may not reflect the true long-term speed of your
                Broadband.
                <br />
                2. This tool should not be used to test 500Mb/1000Mb product
                speeds. Line test results for lines above 330Mb cannot currently
                be verified by the Performance Tester.
                <br />
                <br />
                Please confirm that you have carried out the above checks. The
                speed test may report inaccurate results if you fail to, or are
                not aware of the tools limitations.
              </p>
            </div>
          </div>
        
      </Carousel>
    );
  }
}
export default DCarousel;
